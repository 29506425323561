module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-adobe-launch/gatsby-browser.js'),
      options: {"plugins":[],"scriptUrl":"https://assets.adobedtm.com/84f7e94eaa36/9a42586296fd/launch-88fb4b71da5e.min.js","includeInDevelopment":true,"defaultDataLayer":{"platform":"innovation"},"dataLayerName":"innovation","routeChangeEventName":"innovationRouteChange"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
